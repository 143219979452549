import React, { useState } from "react";
import { useForm } from "react-hook-form";

// Assets
import PrivateImage from "../../assets/public/Private.png";

const PrivateLayout = () => {
  const [younger, setYounger] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (values) => {
    setLoading(true);
    setYounger(false);

    const month = values.month;
    const day = values.day;
    const year = values.year;
  };

  return (
    <div className="w-full h-screen overflow-hidden fixed top-0 z-40 flex items-center justify-center bg-[#00000099] backdrop-blur-lg">
      <div className="w-[400px] min-h-96 h-96 bg-white flex flex-col">
        <figure className="w-full h-36 mb-2">
          <img
            src={PrivateImage}
            className="w-full h-full object-cover"
            alt="Ron Joya"
          />
        </figure>
        <div className="w-[90%] mx-auto mb-4">
          <p className="">Por favor introduzca su fecha de nacimiento</p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-[90%] mx-auto mb-4 flex items-center justify-between">
            <input
              type="text"
              placeholder="Día(Número)"
              {...register("day", {
                required: {
                  value: true,
                },
                maxLength: {
                  value: 2,
                },
                pattern: {
                  value: /^\d*$/,
                },
              })}
              className={`outline-none border-b-2 ${
                errors.day ? "border-redRonJoya" : "border-gray-400"
              } px-1 w-28`}
            />
            <input
              type="text"
              placeholder="Mes(Número)"
              {...register("month", {
                required: {
                  value: true,
                },
                maxLength: {
                  value: 2,
                },
                pattern: {
                  value: /^\d*$/,
                },
              })}
              className={`outline-none border-b-2 ${
                errors.month ? "border-redRonJoya" : "border-gray-400"
              } px-1 w-28`}
            />
            <input
              type="text"
              placeholder="Año(Número)"
              {...register("year", {
                required: {
                  value: true,
                },
                minLength: {
                  value: 4,
                },
                maxLength: {
                  value: 4,
                },
                pattern: {
                  value: /^\d*$/,
                },
              })}
              className={`outline-none border-b-2 ${
                errors.year ? "border-redRonJoya" : "border-gray-400"
              } px-1 w-28`}
            />
          </div>
          <div className="w-[90%] mx-auto mb-2">
            <button className="text-white px-6 py-1 outline-none bg-redRonJoya rounded-md">
              Confirmar
            </button>
          </div>
        </form>
        <div className="w-[90%] mx-auto mb-2">
          <p className="text-xs text-blue-700">{loading && "Verificando"}</p>
          <p className="text-xs text-redRonJoya">
            {younger && "Debes ser mayor de edad"}
          </p>
        </div>
        <div className="w-[90%] mx-auto">
          <p className="text-xs">
            Para acceder a este sitio debes ser mayor de edad en tu país de
            residencia. Al hacer click en "Confirmar", aceptas el Aviso Legal y
            la Política de Privacidad de este sitio.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivateLayout;
