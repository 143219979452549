// Icons
import { GrFacebookOption } from "react-icons/gr";
import { BsInstagram } from "react-icons/bs";
import { TWMixer } from "../../utils/common";
import { cloneElement, isValidElement } from "react";
import { Link, useLocation } from "react-router-dom";

//imagenes
import IMG_Logo from "../../assets/innova/img/logo/innova.png";

//custom icons
import { SocialMediaInstagram } from "../../assets/innova/svg/social_media/instagram";
import { SocialMediaFacebook } from "../../assets/innova/svg/social_media/facebook";
import { SocialMediaWhatsapp } from "../../assets/innova/svg/social_media/whatsapp";

/* const Footer = () => {
  return (
    <footer className="w-[99%] h-80 bg-redRonJoya mx-auto my-2 text-white">
      <div className="w-[90%] md:w-[60%] h-full mx-auto flex items-center justify-evenly flex-col">
        <div className="flex">
          <GrFacebookOption className="text-2xl mr-4 cursor-pointer" />
          <BsInstagram className="text-2xl cursor-pointer" />
        </div>
        <div className="flex">
          <div className="mx-2 md:mx-0">
            <p className="cursor-pointer">Inicio</p>
          </div>
          <div className="hidden md:block px-5 border-x-2 border-white mx-5">
            <p className="cursor-pointer">Productos</p>
          </div>
          <div className="hidden md:block">
            <p className="cursor-pointer">Cocktails</p>
          </div>
          <div className="hidden md:block px-5 border-x-2 border-white mx-5">
            <p className="cursor-pointer">News</p>
          </div>
          <div className="md:hidden px-4 border-x-2 border-white mx-2">
            <p className="cursor-pointer">Sobre</p>
          </div>
          <div className="md:hidden pr-4 border-r-2 border-white mx-2">
            <p className="cursor-pointer">Ron</p>
          </div>
          <div className="mx-2 md:mx-0">
            <p className="cursor-pointer">Contáctenos</p>
          </div>
        </div>
        <div>
          <p className="text-base">
            Lörem ipsum od ohet dilogi. Bell trabel, samuligt, ohöbel utom
            diska. Jinesade bel när feras redorade i belogi. FAR paratyp i
            muvåning, och pesask vyfisat. Viktiga poddradio har un mad och inde.{" "}
          </p>
        </div>
        <div>
          <p>© 2022 | LOREM IPSUM</p>
        </div>
      </div>
    </footer>
  );
}; */

function LinkSocialMediaGroup(){
  return <>
    <LinkSocialMedia to="https://www.instagram.com/alimentosinnovaoficial/?hl=es-la" iconComponent={<SocialMediaInstagram/>} texto="Instagram"/>
    {/* <LinkSocialMedia to="/" iconComponent={<SocialMediaFacebook/>} texto="Facebook"/>
    <LinkSocialMedia to="/" iconComponent={<SocialMediaWhatsapp/>} texto="Whatsapp"/> */}
  </>
}

function Footer({}){
  return <div className="bg-innovaBlanco pt-24 pb-16 text-[#606176]">

    {/* Caja centrada */}
    <section
      className={TWMixer([
        //escritorio
        "lg:max-w-[70%]",
        
        //tablet
        "md:max-w-[60%]",
        
        //telefono (default)
        "mx-auto px-8",
      ])}
    >


      {/* Contenedor de textos */}
      <div className={TWMixer([
        //flex
        "flex justify-between",
      ])}>

        {/* Lorem con logo */}
        <div className={TWMixer([
          //escritorio
          "lg:max-w-[30%]",
          
          //tablet
          "max-w-[60%]",
          
          //telefono (default)
          "",
        ])}>
          <img src={IMG_Logo} className="w-24"/>
          <p className="mb-10 lg:mb-auto">
            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin est urna nullam id. */}
          </p>

          {/* Redes sociales (tablet) */}
          <div className="block lg:hidden">
            <h1 className="text-2xl text-black md:mb-6 mb-0"><b>Cuentas</b></h1>
            <LinkSocialMediaGroup/>
          </div>
        </div>

        {/* Redirects de "Inicio" */}
        <div>
          {/* <h1 className="text-2xl text-black mb-6"><b>Nosotros</b></h1> */}
          <LinkFooter to="/" texto={"Inicio"}/>
          <LinkFooter to="/sobre-nosotros" texto={"Sobre Nosotros"}/>
          <LinkFooter to="/productos" texto={"Productos"}/>
          {/* <LinkFooter to="/novedades" texto={"Novedades"}/> */}
          <LinkFooter to="/contacto" texto={"Contáctanos"}/>
          {/* <LinkFooter to="/faq" texto={"FAQ"}/> */}
        </div>

        {/* Redirects de "Soporte" (solo escritorio) */}
        {/* <div className="hidden lg:block">
          <h1 className="text-2xl text-black mb-6"><b>Soporte</b></h1>
          <LinkFooter to="/soporte" texto={"Soporte"}/>
        </div> */}

        {/* Redes sociales (escritorio) */}
        <div className="hidden lg:block">
          <h1 className="text-2xl text-black mb-6"><b>Cuentas</b></h1>
          <LinkSocialMediaGroup/>
        </div>
      </div>

      {/* Barra de copyright */}
      <div className="pt-10 mt-16 border-t-gray-400 border-t-2">
        Copyright Innova 2022 
      </div>
    </section>
  </div>
}

function LinkFooter({to,texto}){
  const firstPath=useLocation().pathname.split("/").filter((p)=>!!p)[0]

  return <p
    className={TWMixer([
      //escritorio
      "lg:my-4",
      //tablet
      "md:my-8",
      //telefono (default)
      "my-1",

      ...((("/"+(firstPath||""))==to)&&[
        //tablet
        "md:mb-6",
        
        //telefono
        "text-2xl text-black font-bold",
      ]||[])
      
    ])}
  >
    <Link to={to}>{texto}</Link>
  </p>
}

function LinkSocialMedia({to="/",texto,iconComponent}){
  return <a 
    target={"_blank"}
    href={to}
    className="flex items-center my-2 w-min"
  >
    <div className={TWMixer([
      //flex
      "flex justify-center items-center",
      //sizing
      "w-8 h-8",
      //bg
      "bg-[#333333]",
      "rounded-full",
    ])}>
    {
      isValidElement(iconComponent)&&
      cloneElement(iconComponent,{
        className:iconComponent.className+" fill-white h-full w-[55%]"
      })
    }
    </div>
    <p className="text-sm uppercase mx-2">{texto}</p>
  </a>
}

export default Footer;
