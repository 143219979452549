import React, { isValidElement, useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

// Icons
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import { FiMenu } from "react-icons/fi";

//custom icons
import { SocialMediaInstagram } from "../../assets/innova/svg/social_media/instagram";
import { SocialMediaFacebook } from "../../assets/innova/svg/social_media/facebook";
import { SocialMediaWhatsapp } from "../../assets/innova/svg/social_media/whatsapp";

//imagenes
import IMG_LogoNavbar from "../../assets/innova/img/logo/innova.png";

// Styles
import "./index.css";
import { TWMixer } from "../../utils/common";
import { cloneElement } from "react";
import { useMemo } from "react";
/* 
const Navbar = () => {
  const [indexHovered, setIndexHovered] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);

  const onOptionHovered = (index) => {
    if (indexHovered === index) {
      setIndexHovered(-1);
    }

    setIndexHovered(index);
  };

  return (
    <div>
      <nav className="w-full fixed top-0 z-10 bg-[inherit] backdrop-blur-md">
        <ul>
          <div className="w-[98%] h-12 px-4 mx-auto flex items-center justify-between">
            <div
              className="flex text-white items-center cursor-pointer"
              onClick={() => setIsOpen(true)}
              id="text-menu"
            >
              <HiOutlineMenuAlt1 className="text-2xl mr-1" />
              <li className="text-xl">Menu</li>
            </div>
            <div className="flex text-white items-center cursor-pointer relative z-[30]">
              <li className="text-xl mr-1">ES</li>
              <IoIosArrowDown className="text-2xl" />
            </div>
          </div>
        </ul>
      </nav>
      <div
        className={`${
          isOpen ? "translate-x-0" : "-translate-x-[100%]"
        } w-full h-screen bg-green-600 fixed z-20 flex flex-col md:flex-row transform transition duration-700`}
      >
        <div className="w-full md:w-[60%] lg:w-6/12 h-[50%] md:h-full bg-redRonJoya text-white">
          <div
            className="inline-flex items-center cursor-pointer mt-4 ml-4"
            onClick={() => setIsOpen(false)}
          >
            <AiOutlineClose className="text-2xl mr-1" />
            <p className="text-base">Cerrar</p>
          </div>
          <nav className="mt-4 md:mt-24">
            <ul>
              <div className="h-12 px-6 md:px-16 lg:px-24 py-2 mb-[1px]">
                <NavLink
                  className="text-xl md:text-2xl"
                  to="/"
                  onClick={() => setIsOpen(false)}
                  onMouseEnter={() => onOptionHovered(1)}
                  onMouseLeave={() => setIndexHovered(-1)}
                >
                  <li>Inicio</li>
                </NavLink>
              </div>
              <div className="relative h-12 px-6 md:px-16 lg:px-24 py-2 mb-[1px]">
                <NavLink
                  className="text-xl md:text-2xl"
                  to="/sobre-nosotros"
                  onClick={() => setIsOpen(false)}
                  onMouseEnter={() => onOptionHovered(2)}
                  onMouseLeave={() => setIndexHovered(-1)}
                >
                  <li className="relative z-10">Sobre nosotros</li>
                </NavLink>
              </div>
              <div className="h-12 px-6 md:px-16 lg:px-24 py-2 mb-[1px]">
                <NavLink
                  className="text-xl md:text-2xl"
                  to="/productos"
                  onClick={() => setIsOpen(false)}
                  onMouseEnter={() => onOptionHovered(3)}
                  onMouseLeave={() => setIndexHovered(-1)}
                >
                  <li>Ron</li>
                </NavLink>
              </div>
              <div className="h-12 px-6 md:px-16 lg:px-24 py-2">
                <NavLink
                  className="text-xl md:text-2xl"
                  to="/contacto"
                  onClick={() => setIsOpen(false)}
                  onMouseEnter={() => onOptionHovered(4)}
                  onMouseLeave={() => setIndexHovered(-1)}
                >
                  <li>Contáctenos</li>
                </NavLink>
              </div>
            </ul>
          </nav>
        </div>
        <div className="w-full md:w-[40%] lg:w-6/12 h-[50%] md:h-full bg-[#747474] text-white">
          <div className="px-6 md:px-8 lg:px-24 py-2 mt-4 md:mt-32">
            <div className="mb-2">
              <p className="text-xl mb-2">CONTACTO</p>
              <p className="">Ubicación</p>
              <p className="">Tel.</p>
              <p className="">E-mail: info@compañia.com</p>
            </div>
            <button className="px-5 py-2 my-2 bg-redRonJoya rounded-sm">
              DISTRIBUIDORES
            </button>
            <div className="mt-2">
              <p className="mb-2">SOCIAL</p>
              <p className="">REDES SOCIALES</p>
              <p className="">REDES SOCIALES</p>
              <p className="">REDES SOCIALES</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}; */

function Navbar({}){
  return <>
    <NavbarDesktop/>
    <NavbarTabletAndPhone/>
  </>
}

function LinkNavbarGroup({}){
  return <>
    <LinkNavbar to="/" texto={"Inicio"}/>
    <LinkNavbar to="/sobre-nosotros" texto={"Sobre Nosotros"}/>
    <LinkNavbar to="/productos" texto={"Productos"}/>
    {/* <LinkNavbar to="/novedades" texto={"Novedades"}/> */}
    <LinkNavbar to="/contacto" texto={"Contáctanos"}/>
    {/* <LinkNavbar to="/faq" texto={"FAQ"}/> */}
  </>
}

function LinkSocialMediaGroup(){
  return <>
    <LinkSocialMedia to="https://www.instagram.com/alimentosinnovaoficial/?hl=es-la" iconComponent={<SocialMediaInstagram />}/>
    {/* <LinkSocialMedia to="/" iconComponent={<SocialMediaFacebook />} />
    <LinkSocialMedia to="/" iconComponent={<SocialMediaWhatsapp />} /> */}
  </>
}

function NavbarDesktop({}){
  return <nav className={TWMixer([
    //solo para escritorio
    "hidden lg:flex",
    //position
    "w-full fixed top-0 z-50 ",
    //sizing
    "w-[90vw] min-h-[4rem] mx-[5vw] px-4",

    "rounded-b-3xl",
    
    //background
    "bg-white",
    
    //flex displaying
    "justify-between items-center",
  ])}>
    {/* Imagen */}
    <Link to="/">
      <img
        src={IMG_LogoNavbar}
        className="max-h-12"
      />
    </Link>

    {/* Rutas */}
    <div className="text-gray-600 min-w-[50%] flex justify-around items-center">
      <LinkNavbarGroup/>
    </div>
    
    {/* Redes sociales */}
    <div className="flex" >
      <LinkSocialMediaGroup/>
    </div>
  </nav>
}

function NavbarTabletAndPhone({}){

  const path=useLocation().pathname;

  //esto es para que se cierre el menu de tablet cuando se escoja una opcion
  useEffect(() => {
    document.getElementById("tablet_nav_open_menu").checked=false
  }, [path])
  

  return <nav className={TWMixer([
    
    //tablet
    "md:mx-[5vw] md:w-[90vw]",
    
    //position
    "w-full fixed top-0 z-50 ",
    //sizing
    "min-h-[5rem] p-4",
    
    "rounded-b-3xl",
    
    //background
    "bg-white",
    
    //flex displaying
    "flex lg:hidden",
    "justify-between items-center",
  ])}>


    {/* Tres barras para abrir menu, label del input siguiente */}
    <label className="h-full cursor-pointer" htmlFor="tablet_nav_open_menu">
      <FiMenu className="h-8 w-8" />
    </label>

    {/* Input invisible que abre y cierra el menu */}
    <input className="hidden peer" type={"checkbox"} id="tablet_nav_open_menu"/>

    {/* Covertor de pantalla para overflow-hidden*/}
    <div
      className={TWMixer([
        //displayng
        "overflow-hidden transition-all",
        //position
        "fixed top-0 -left-full peer-checked:left-0",
        //sizing
        "h-screen w-full",
        
        //bg
        //"bg-[#00000000] peer-checked:bg-[#00000030]",
        
        //telefono (default)
        "",
      ])}
    >
      {/* Div creado para que al clicar fuera del menu, este se cierre */}
      <label className="absolute top-0 left-0 w-full h-full" /*  bg-black */ htmlFor="tablet_nav_open_menu"></label>

      {/* Menu */}
      <div
        className={TWMixer([
          // tablet
          "md:w-1/2 md:px-12",

          //telefono (default)
          //base
          "bg-innovaBlanco relative",
          //sizing
          "w-full h-full px-4 py-6",
        ])}
      >
        {/* Boton de cerrar */}
        <label className="text-white" htmlFor="tablet_nav_open_menu">
          <IoMdClose className="w-8 h-8 cursor-pointer" fill="#000"/>
        </label>

        {/* Opciones */}
        <LinkNavbarGroup/>

        {/* Redes sociales */}
        <div className="absolute bottom-6 left-12">
          <img src={IMG_LogoNavbar} className="mb-4"/>
          <div className="flex justify-between">
            <LinkSocialMediaGroup/>
          </div>
        </div>
        
      </div>
    </div>

    {/* Imagen */}
    <Link to="/">
      <img
        src={IMG_LogoNavbar}
        className="max-h-12"
      />
    </Link>
  </nav>
}

function LinkNavbar({to="/",texto}){

  const pathname=useLocation().pathname
  const isThisPath=useMemo(()=>{
    let splittedPath=pathname.split("/").filter((p)=>!!p);

    return (splittedPath[0]||"")==(to.slice(1))
  },[pathname])

  return <Link
    to={to}
    className={TWMixer([
      //base
      "flex flex-col mx-1",
      //si este link es el current path
      isThisPath&&"font-bold",

      ////after
      //content
      isThisPath&&"after:content-['-'] after:text-transparent after:bg-innovaRojo after:text-[0rem]",
      //sizing
      isThisPath&&"after:p-1 after:mx-auto after:rounded-full after:h-1",
      
      //escritorio
      "lg:my-0 lg:mx-4 lg:w-auto",
      
      //tablet y telefono
      "w-max",
      
      //telefono (default)
      "my-6",
    ])}
  >
    <h1 className={TWMixer([
      //escritorio
      "",
      
      //tablet
      "",
      
      //telefono (default)
      "",
    ])}>{texto}</h1>
  </Link>
}

function LinkSocialMedia({to="/",iconComponent}){
  return <a 
    target={"_blank"}
    href={to}
    className={TWMixer([
      //flex
      "flex justify-center items-center",
      //sizing
      "w-8 h-8 lg:mx-2",
      //bg
      "bg-[#333333]",
      "rounded-full"
    ])}
  >
    {
      isValidElement(iconComponent)&&
      cloneElement(iconComponent,{
        className:iconComponent.className+" fill-white w-full h-[55%]"
      })
    }
  </a>
}

export default Navbar;
