import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Layout
import PublicLayout from "./layout";

// Components
import Loader from "./components/loader";

// Styles
import "./index.css";

function LazyLoader({path}){

  const LazyComponent = React.lazy(() => import(`${path}`))

  return <React.Suspense fallback={<Loader />}>
    <LazyComponent />
  </React.Suspense>
}

// Pages
// const Home = React.lazy(() => import("./pages/home"));
// const Products = React.lazy(() => import("./pages/products"));
// const About = React.lazy(() => import("./pages/about"));
// const Contact = React.lazy(() => import("./pages/contact"));
const Home = <LazyLoader      path={"./pages/home"}/>
const Products = <LazyLoader  path={"./pages/products"}/>
const About = <LazyLoader     path={"./pages/about"}/>
const Contact = <LazyLoader   path={"./pages/contact"}/>

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    {/* <React.Suspense fallback={<Loader />}> */}
      <Routes>
        <Route path="/"                 element={<PublicLayout/>}>
          <Route index                    element={Home}/>
          <Route path="productos"         element={Products}/>
          <Route path="sobre-nosotros"    element={About}/>
          <Route path="contacto"          element={Contact}/>
        </Route>
        {/* Home
        <Route path="/" element={<PublicLayout />}>
          <Route index element={<Home />} />
        </Route>
        {Products}
        <Route path="/productos" element={<PublicLayout />}>
          <Route index element={<Products />} />
        </Route>
        {About Us}
        <Route path="/sobre-nosotros" element={<PublicLayout />}>
          <Route index element={<About />} />
        </Route>
        {Contact}
        <Route path="/contacto" element={<PublicLayout />}>
          <Route index element={<Contact />} />
        </Route> */}
      </Routes>
    {/* </React.Suspense> */}
  </BrowserRouter>
);
