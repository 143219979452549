import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

// Components
import Navbar from "./navbar";
import Footer from "./footer";
import PrivateLayout from "./private";

import { TWMixer } from "utils/common";

const PublicLayout = () => {
  return (
    <div 
      className={TWMixer([
        //sizing
        "w-full min-h-screen",
        //flex,
        "flex flex-col",
      ])}
    >
      <header className="">
        <Navbar />
      </header>
      <div>
        <Outlet />
      </div>
      <footer className="mt-auto">
        <Footer />
      </footer>
    </div>
  );
};

export default PublicLayout;
